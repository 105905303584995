<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <script-engines-select
        :key="`${step.id}-scriptEngine`"
        :default-value="properties.scriptEngine"
        step-label="graphql"
        :readonly="!canEdit"
        required
        @change="handleChange('scriptEngine', $event)"
      />
    </v-col>
    <credentials-select
      :key="`${step.id}-credentialname`"
      step-type="GRAPHQL"
      :properties="properties"
      step-label="graphql"
      :readonly="!canEdit"
      :required="false"
      @change="handleChange('credentialName', $event)"
    />
    <v-col cols="12" class="py-1">
      <add-key-value
        :key="`${step.id}-variables`"
        type="variables"
        step-type="GRAPHQL"
        :can-edit="canEdit"
        :data="properties.variables"
        :single-step="step"
        @dataChanged="handleChange('variables', $event)"
      />
    </v-col>
    <v-col cols="12" class="mt-6 mb-1">
      <add-key-value
        :key="`${step.id}-headers`"
        type="headers"
        step-type="GRAPHQL"
        :can-edit="canEdit"
        :data="properties.headers"
        :single-step="step"
        @dataChanged="handleChange('headers', $event)"
      />
    </v-col>
    <v-col cols="12" class="mt-6" style="position: relative">
      <text-input-with-editor-button
        :key="`${step.id}-query`"
        :rules="[v => !!v || $lang.labels.required]"
        :default-value="properties.query"
        step-label="graphql"
        required
        :readonly="!canEdit"
        @change="handleChange('query', $event)"
        @openEditor="$emit('openEditor', $event, 'query',
                           { text: '', value: properties.query, parentType: 'query' }
        )"
      />
    </v-col>
    <v-col cols="12" class="pt-1">
      <v-text-field
        :key="`${step.id}-responseCode`"
        v-model="properties.responseCode"
        outlined
        dense
        :label="$lang.labels.responseCode"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        :label="$lang.labels.qraphQlResponseContentTargetObject"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>
    <v-col cols="12" class="position-relative">
      <text-input-with-editor-button
        :key="`${step.id}-targetUrl`"
        :rules="[v => !!v || $lang.labels.required]"
        :default-value="properties.targetUrl"
        step-label="graphql"
        :label="$lang.labels.targetUrl"
        required
        :rows="1"
        :readonly="!canEdit"
        @change="handleChange('targetUrl', $event)"
        @openEditor="$emit('openEditor', $event, 'targetUrl',
                           { text: '', value: properties.targetUrl, parentType: 'targetUrl' }
        )"
      />
    </v-col>
    <v-col cols="9">
      <v-text-field
        :key="`${step.id}-tempTimeout`"
        v-model="tempTimeout"
        outlined
        dense
        :label="$lang.labels.changeableTimeout"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>
    <v-col cols="3">
      <v-select
        :key="`${step.id}-timeUnit`"
        v-model="timeUnit"
        :items="TIME_UNITS"
        :label="$lang.labels.unit"
        outlined
        dense
        required
        class="pl-2"
        :readonly="!canEdit"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  CredentialsSelect,
  TextInputWithEditorButton,
  ScriptEnginesSelect
} from './components'
import AddKeyValue from '../KeyValPairModal'
import StepMixin from './StepMixin'

import { TIME_UNITS, DEFAULT_TIME_UNIT } from './constants'
import { predictBestTimeUnitFromMsValue } from '@/utils/helpers'

export default {
  name: 'GraphQLStep',
  components: {
    AddKeyValue,
    CredentialsSelect,
    ScriptEnginesSelect,
    TextInputWithEditorButton
  },
  mixins: [StepMixin],
  data() {
    return {
      TIME_UNITS,
      timeUnit: DEFAULT_TIME_UNIT,
      tempTimeout: 0
    }
  },
  watch: {
    timeUnit: {
      handler(val) {
        this.properties.timeout = this.$options.filters.unitFormatter(this.tempTimeout, val, 'MSec')
      }
    },
    tempTimeout: {
      handler(val) {
        this.properties.timeout = this.$options.filters.unitFormatter(val, this.timeUnit, 'MSec')
      }
    }
  },
  async created() {
    if (this.properties?.timeout) {
      const unitTempTimeout = await predictBestTimeUnitFromMsValue(this.properties.timeout)

      this.timeUnit = unitTempTimeout.unit
      this.tempTimeout = unitTempTimeout.value
    }

    if (!this.properties.headers) this.properties.headers = {}
    if (!this.properties.timeout) {
      this.tempTimeout = 15
      this.properties.timeout = '15000'
    }
  }
}
</script>
